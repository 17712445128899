// extracted by mini-css-extract-plugin
export var container = "website-module--container--ca28a";
export var customlink = "website-module--customlink--1e60a";
export var field = "website-module--field--fce6f";
export var hero = "website-module--hero--7cf2e";
export var herobg = "website-module--herobg--008b3";
export var leftpart = "website-module--leftpart--c32ab";
export var linksite = "website-module--linksite--2a3e1";
export var mainlayer = "website-module--mainlayer--7fc99";
export var order0 = "website-module--order0--715b8";
export var order1 = "website-module--order1--fcae0";
export var par = "website-module--par--cdd8e";
export var policylink = "website-module--policylink--9616a";
export var rightpart = "website-module--rightpart--cf0fa";
export var section = "website-module--section--82058";
export var sendbutton = "website-module--sendbutton--09a80";
export var subsection = "website-module--subsection--74a41";
export var tech = "website-module--tech--4c54f";
export var white = "website-module--white--beece";